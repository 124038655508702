import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

import GoogleStructuredData from '../components/structuredData';
import IntroTopSection from '../components/introTopSection';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import { PACDisclaimer, ConnieChanDisclaimer, DumpDeanDisclaimer } from '../components/disclaimers';
import ScrollSpy, { MOBILE_HEADER_OFFSET } from '../components/scrollSpy';
import getLang from '../utils/getLang';
// import Campaign from '../components/content/campaign';  // This was adding 1.4MB to page load!!
import shortcodes from '../components/mdxCommon';
import useIsPageScrolled from '../hooks/useIsPageScrolled';

const VoterGuideTemplate = ({ location = {}, data = {} }) => {
  const { search = '' } = location;
  const { mdx = {}, allMdx = {}, site = {} } = data;
  const {
    slug: slug,
    frontmatter: {
      post_type: postType,
      is_pac: isPACFromData,
      featured_image: featuredImage,
      hide_featured_image: hideFeaturedImage,
      hide_top: hideTop,
      open_graph_image: openGraphImage,
      featured_image_credit_link: creditLinkFromData,
      featured_image_credit: creditLinkTextFromData,
      sub_title: subTitleFromData,
      date,
      date_modified: dateModifiedFromData,
      title,
      election_name: electionNameFromData,
      description,
      show_lang: showLang,
      lang: lang,
      used_langs: usedLangs,
      hero_image_alt: heroImageAlt,
      intro_text: introText,
    } = {},
    body,
  } = mdx;

  const introComponents = introText ? <ReactMarkdown>{introText}</ReactMarkdown> : null;
  const queryParam = new URLSearchParams(search);
  const isPAC = queryParam.get('ref') === 'pac' || isPACFromData;
  const isConniePAC = queryParam.get('ref') === 'd1pac';
  const isDeanPAC = queryParam.get('ref') === 'd5pac';
  const heroImage = featuredImage && !hideFeaturedImage ? getImage(featuredImage) : null;
  const showTop = !hideTop;
  const openGraphImageResized = openGraphImage?.childImageSharp.resize || null;
  const creditLink = creditLinkFromData || null;
  const creditLinkText = creditLinkTextFromData || null;
  const subTitle = subTitleFromData || null;
  const dateModified = dateModifiedFromData;
  const electionName = electionNameFromData || null;
  const currentLang = getLang(slug);
  const isPageScrolled = useIsPageScrolled(1000);

  useEffect(() => {
    if (typeof window !== 'undefined' && location.hash) {
      const element = document?.getElementById(location.hash.split('#')[1]);
      if (!element) return;
      const target = element?.classList.contains('sticky-heading')
        ? element?.parentNode.parentNode
        : element;
      const offset = target?.matches('h5,h4') ? target.parentNode.firstChild.clientHeight : 0;
      setTimeout(() => {
        window?.scrollTo({
          behavior: 'instant',
          top:
            target.getBoundingClientRect().top +
            window?.scrollY -
            (window?.innerWidth < 1024 ? MOBILE_HEADER_OFFSET : 0) -
            offset,
        });
      }, 200);
    }
  }, []);

  return (
    <Layout>
      {postType === 'voter-guide' ? (
        <GoogleStructuredData site={site} slug={slug} frontmatter={mdx.frontmatter} />
      ) : null}
      <SEO
        title={title}
        description={description}
        image={openGraphImageResized}
        pathname={location.pathname}
      />
      <div className="bg-brand-blue-1 mt-10 px-5">
        {showTop ? (
          <IntroTopSection
            pathname={location.pathname}
            title={title}
            subTitle={subTitle}
            date={date}
            dateModified={dateModified}
            heroImage={heroImage}
            heroImageAlt={heroImageAlt}
            creditLink={creditLink}
            creditLinkText={creditLinkText}
            introComponents={introComponents}
            url={`https://growsf.org/${slug}`}
            shareText="Share This Voter Guide"
            showLang={showLang}
            currentLang={currentLang}
            usedLangs={usedLangs}
          />
        ) : (
          ''
        )}
        <div className="bg-brand-blue-1 overflow-x-clip -mx-5 px-5">
          <div className="w-full gap-4 max-w-7xl mx-auto justify-between xl:px-8 lg:grid lg:grid-cols-11">
            <div className="z-40 sticky top-0 lg:self-start lg:col-span-3 lg:w-auto lg:block">
              <ScrollSpy showContent={isPageScrolled} />
            </div>
            <div
              className="voters relative mx-auto prose sm:prose-sm md:prose-base lg:prose-lg lg:col-span-8 tracking-wide"
              data-js-body
            >
              <MDXProvider components={shortcodes}>
                <MDXRenderer>{body}</MDXRenderer>
              </MDXProvider>
            </div>
          </div>
        </div>

        <div className="pb-8">
          <PACDisclaimer />
          {isDeanPAC && <DumpDeanDisclaimer />}
          {isConniePAC && <ConnieChanDisclaimer />}
        </div>

        <PastVoterGuides data={data} />
      </div>
    </Layout>
  );
};

const PastVoterGuides = ({ data }) => {
  const lang = data.mdx.frontmatter.lang;
  const pastGuides = data.allMdx.nodes;

  let filteredGuides = [];
  // If the current voter guide is english, filter out all non-english guides
  // If the current voter guide is not english, show that language if available otherwise show english
  if (lang === 'en') {
    filteredGuides = pastGuides.filter((guide) => guide.frontmatter.lang === 'en');
  } else {
    for (let i = 0; i < pastGuides.length; i++) {
      if (pastGuides[i].frontmatter.lang === lang) {
        filteredGuides.push(pastGuides[i]);
      } else if (
        pastGuides[i].frontmatter.used_langs &&
        pastGuides[i].frontmatter.used_langs.includes(lang)
      ) {
        // We're already listing the correct language entry here; skip it!
        continue;
      } else {
        filteredGuides.push(pastGuides[i]);
      }
    }
  }
  return (
    <div className="bg-white overflow-x-hidden">
      <div className="w-full bg-brand-blue-1">
        <div className="flex flex-col gap-2 pb-4 items-center relative max-w-7xl mx-auto px-5 sm:pb-6 sm:gap-0 xl:px-8">
          <h1 className="text-left text-base font-bold capitalize sm:text-lg text-brand-blue-5 md:text-xl lg:text-2xl sm:font-black w-full">
            Past GrowSF Voter Guides
          </h1>
          <ul>
            {filteredGuides?.map((node, index) => (
              <li>
                <Link
                  key={index}
                  to={`/${node.slug}`}
                  className="flex flex-col gap-4 px-2 py-4 -mx-2 ring-offset-2 overflow-hidden isolate border-b border-brand-gray-2 hover:border-white focus:border-white hover:border-1 focus:border-1 hover:ring-2 focus:ring-2 hover:ring-blue-300 focus:ring-blue-300 hover:rounded-md focus:rounded-md sm:flex-row xl:px-6 xl:py-6 xl:-mx-6 sm:gap-12"
                >
                  <GatsbyImage
                    className="w-full object-contain max-h-40 shadow-lg overflow-hidden isolate rounded-md sm:max-w-[247px] sm:max-h-36"
                    image={
                      node.frontmatter.image
                        ? getImage(node.frontmatter.image.childImageSharp)
                        : opengraph
                        ? opengraph
                        : ''
                    }
                    loading="lazy"
                    alt={node.frontmatter.image_alt || 'listing_opengraph'}
                  />
                  <div className="flex flex-col my-auto gap-1.5">
                    <h2 className="text-left text-base font-bold capitalize sm:text-base text-brand-blue-5 md:text-lg lg:text-xl sm:font-black">
                      {node.frontmatter.title}
                    </h2>
                    <span className="text-left text-xs italic font-light text-gray-700">
                      {node.frontmatter.date_modified}
                    </span>
                    <span className="line-clamp-4 text-sm font-light text-gray-700 sm:text-lg sm:line-clamp-2">
                      {node.summary}
                    </span>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query ($slug: String) {
    mdx(slug: { eq: $slug }) {
      tableOfContents
      body
      slug
      frontmatter {
        title
        sub_title
        intro_text
        election_name
        description
        post_type
        date(formatString: "MMMM DD, YYYY")
        date_modified(formatString: "MMMM DD, YYYY")
        # header_style
        show_lang
        lang
        used_langs
        donorbox_campaign
        donorbox_type
        donor_subtitle
        dateISO: date
        dateModifiedISO: date_modified
        featured_image_credit_link
        featured_image_credit
        featured_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        hide_featured_image
        hide_top
        open_graph_image: featured_image {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
        is_pac
      }
    }
    allMdx(
      sort: {
        order: [ASC, DESC]
        fields: [frontmatter___sort_priority_override, frontmatter___date]
      }
      filter: { frontmatter: { post_type: { in: ["voter-guide"] }, hide: { ne: true } } }
    ) {
      nodes {
        frontmatter {
          post_type
          sort_priority_override
          date_modified
          title
          election_name
          lang
          used_langs
          image: featured_image {
            childImageSharp {
              gatsbyImageData
            }
          }
          image_alt: featured_image_alt
        }
        id
        slug
        summary: excerpt(pruneLength: 200)
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default VoterGuideTemplate;
