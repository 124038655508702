import React from 'react';
import { Helmet } from 'react-helmet';
import { getImage } from 'gatsby-plugin-image';

const GoogleStructuredData = ({ site, slug, frontmatter }) => {
  const img = getImage(frontmatter.featured_image);
  const dateModified = frontmatter.dateModifiedISO
    ? frontmatter.dateModifiedISO
    : frontmatter.dateISO;
  let data = [
    {
      '@context': 'https://schema.org',
      '@type': 'Article',
      name: frontmatter.title,
      headline: frontmatter.title,
      description: frontmatter.description,
      image: [site.siteMetadata.siteUrl + img.images.fallback.src],
      datePublished: frontmatter.dateISO,
      dateModified: dateModified,
      author: [
        {
          '@type': 'Organization',
          name: 'GrowSF',
          url: site.siteMetadata.siteUrl,
        },
      ],
      publisher: [
        {
          '@type': 'Organization',
          name: 'GrowSF',
          url: site.siteMetadata.siteUrl,
        },
      ],
    },
  ];
  if (slug != 'voter-guide/') {
    data.push({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Voter Guides',
          item: site.siteMetadata.siteUrl + '/voter-guide/',
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: frontmatter.election_name || 'Voter Guide',
          item: site.siteMetadata.siteUrl + '/' + slug,
        },
      ],
    });
  } else {
    data.push({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Voter Guides',
          item: site.siteMetadata.siteUrl + '/' + slug,
        },
      ],
    });
  }
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(data, null, 2)}</script>
    </Helmet>
  );
};

export default GoogleStructuredData;
